<template>
	<v-container fluid>
		<v-row>
			<v-col cols="3">
				<SidebarFilters
					:filter-name="filters"
					title="GMB Navigations"
				></SidebarFilters>
			</v-col>

			<v-col cols="9">
				<v-card>
					<transition name="fade" mode="out-in">
						<router-view></router-view>
					</transition>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import SidebarFilters from '@/components/SidebarFilters'
// import UnoSaveButton from '@/components/Buttons/UnoSaveButton'
export default {
	components: {
		SidebarFilters,
		// UnoSaveButton,
	},
	data() {
		return {
			filters: [
				// { name: 'Insights', path: 'insights' },
				// { name: 'Summary', path: 'summary' },
				{ name: 'Reviews', path: 'reviews' },
				{ name: 'Question & Answers', path: 'qa' },
				{ name: 'Posts', path: 'posts' },
				{
					name: 'Schedule Posts',
					path: 'scheduled-posts',
				},
				{ name: 'Media', path: 'media' },
				// Below one is just temporary fix will do in revamping the whole GMB
				{
					name: '-------------------------------------------',
					path: '',
				},
				{ name: 'Accounts', path: 'accounts' },
				{ name: 'Organizations', path: 'orgs' },
				{ name: 'Locations', path: 'locations' },
			],
		}
	},
	computed: {
		selectedOrg: {
			get() {
				return this.$store.getters['gmb/getSelectedOrg']
			},
		},
	},
}
</script>
